// import { Routes, Route,BrowserRouter } from "react-router-dom";
// import axios from 'axios';
import { useEffect, useState } from 'react';
import '../../App.css';
import './ProductPage.css';
import axios from 'axios';
import { BASE_URL } from '../../url';
import CategoryAll from '../../components/CategoryAll/CategoryAll';
import Pagination from '../../components/Pagination/Pagination';
import { Link } from 'react-router-dom';
import Carousel from 'nuka-carousel';
import arrleft from '../../assets/arrleft.svg';
import arrRight from '../../assets/arrRight.svg';
import sclad from '../../assets/sclad.png';
import pay from '../../assets/pay.png';
import noImg from '../../assets/noImg.png';
import { getInfoCart } from '../../store/Actions/Action';
import { useDispatch } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import ReactInputMask from 'react-input-mask';
import PhoneInput from '../../components/PhoneInput/PhoneInput';
import PreorderForm from '../../components/PreorderForm/PreorderForm';

const ProductPage = () => {
  let [product, setProduct] = useState();
  // let [description,se]=useState()
  let [quantity, setQuantity] = useState(1);
  let [isSuccessAdd, setSuccessAdd] = useState(false);

  // let [isSuccessAdd, setSuccessAdd] = useState(false);
  // const [modalCreateOrderOpen, setModalCreateOrderOpen] = useState(false);

  // let [dataUser, setDataUser] = useState();
  // let [errorData, setErrorData] = useState({
  //   email: '',
  //   fullname: '',
  //   phoneNumber: '',
  // });
  const dispatch = useDispatch();

  const regular = new RegExp('[0-9]{1,}');
  // useEffect(() => {
  //   dataUser && setErrorData(validate(dataUser));
  //   console.log(errorData);
  // }, [dataUser]);
  const refreshCard = (arr, id) => {
    return arr.map((n) =>
      n.id === id
        ? {
            ...n,
            quantity:
              n.quantity + quantity < product.quantity
                ? n.quantity + quantity
                : product.quantity,
          }
        : n
    );
  };

  useEffect(() => {
    window.scrollTo(0, 370);
    axios
      .get(BASE_URL + 'products/' + window.location.pathname.split('/')[2])
      .then((res) => {
        setProduct(res.data.product);
      })
      .catch((err) => console.log(err));
  }, []);
  let description =
    product && product.description ? product.description.split('\n') : null;
  const addCart = () => {
    // localStorage.setItem()
    let prod = localStorage.getItem('cart')
      ? JSON.parse(localStorage.getItem('cart'))
      : [];
    if (prod.find((pr) => pr.id === product.id)) {
      const newCard = refreshCard(prod, product.id);
      localStorage.setItem('cart', JSON.stringify(newCard));
    } else {
      prod.push({
        id: product.id,
        name: product.name,
        price: product.price,
        quantity: quantity,
        img: product.imageUrl ? product.imageUrl : '',
        article: product.article,
        availableQuantity: product.quantity,
      });
      setSuccessAdd(true);
      setTimeout(() => setSuccessAdd(false), 2000);

      localStorage.setItem('cart', JSON.stringify(prod));
    }
    dispatch(getInfoCart());
  };

  // const changeInputUser = (event) => {
  //   event.persist();
  //   setDataUser((prev) => {
  //     return {
  //       ...prev,
  //       [event.target.name]: event.target.value,
  //     };
  //   });
  // };
  // const validate = (values) => {
  //   let errors = {};
  //   if (!values.fullname) {
  //     errors.fullname = 'Поле ФИО обязательно для ввода';
  //   } else if (values.fullname < 2) {
  //     errors.fullname = 'Поле ФИО должно быть длиннее двух символов';
  //   }

  //   if (!values.email) {
  //     errors.email = 'Поле email обязательно для ввода';
  //   } else if (!values.email) {
  //     errors.email = 'Поле email обязательно для ввода';
  //   } else if (
  //     /^[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/.test(values.email) === false
  //   ) {
  //     errors.email =
  //       'Поле email может содержать только буквы латинского алфавита';
  //   }

  //   if (!values.phoneNumber) {
  //     errors.phoneNumber = 'Поле номер телефона обязательно для ввода';
  //   }

  //   return errors;
  // };
  // const onCreateOrder = () => {
  //   dataUser.products = [{ id: product.id, quantity: quantity }];
  //   let strPhoneNumber = dataUser.phoneNumber;
  //   strPhoneNumber = strPhoneNumber.replace(/[^+\d]/g, '');
  //   dataUser.phoneNumber = strPhoneNumber;
  //   axios
  //     .post(BASE_URL + 'preorder', dataUser)
  //     .then((res) => {
  //       console.log(res);
  //       setModalCreateOrderOpen(false);
  //     })
  //     .catch((err) => console.log(err));
  // };
  return (
    <>
      {isSuccessAdd && (
        <div className="seccess-add">
          {/* <img src={addcart} alt='добавлено в корзину'/> */}
          Товар добавлен в корзину
        </div>
      )}
      {product && (
        <>
          {/* <Modal
            isOpen={modalCreateOrderOpen}
            onClose={() => setModalCreateOrderOpen(false)}
            content={
              <div>
                <h2>Заявка на заказ</h2>
                <div className="madal-product">
                  <div className="modal-product-img">
                    <img src={product.imagesUrls[0]} alt={product.name} />
                  </div>
                  <h3>{product.name}</h3>
                </div>
                <div className="modal-create-order-form">
                  <div>ФИО:</div>
                  <input
                    name="fullname"
                    type="text"
                    placeholder="ФИО"
                    className="inp"
                    onChange={changeInputUser}
                  />
                  {errorData.fullname && (
                    <div className="err">{errorData.fullname}</div>
                  )}

                  <div>Электронная почта:</div>
                  <input
                    name="email"
                    type="email"
                    placeholder="Email"
                    className="inp"
                    onChange={changeInputUser}
                  />
                  {errorData.email && (
                    <div className="err">{errorData.email}</div>
                  )}

                  <div>Номер телефона:</div>
                  <PhoneInput onChange={changeInputUser} />
                  {errorData.phoneNumber && (
                    <div className="err">{errorData.phoneNumber}</div>
                  )}
                  <div>Комментарий</div>
                  <textarea
                    name="comment"
                    // className="inp"
                    type="text"
                    // placeholder='Комментарий'
                    onChange={changeInputUser}
                  />
                </div>
                <button
                  disabled={Object.keys(errorData).length !== 0}
                  className="to-cart-btn"
                  onClick={onCreateOrder}
                >
                  Оформить
                </button>
              </div>
            }
          /> */}
          <div className="product">
            <h2>{product.name}</h2>
            <div className="description-prod">
              <div className="img-prod">
                <Carousel
                  renderCenterLeftControls={({ previousSlide }) => (
                    <div className="btn-arrow-left" onClick={previousSlide}>
                      <img src={arrleft} alt="left" />
                    </div>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <div className="btn-arrow" onClick={nextSlide}>
                      <img src={arrRight} alt="right" />
                    </div>
                  )}
                  adaptiveHeightAnimation="true"
                >
                  {/* <img className='book-img' src={data.book.mainImageLink} alt={data.book.title}/> */}
                  {product.imagesUrls &&
                    product.imagesUrls.map((i, index) => (
                      <div>
                        <img
                          key={index}
                          className="prod-img"
                          src={i}
                          alt="img-book"
                        />
                      </div>
                    ))}
                </Carousel>
              </div>
              <div className="desc-prod">
                {/* <p>
                <span className="desc-text">Производитель: </span>{" "}
                {product.brand}
              </p> */}
                <p>
                  <span className="desc-text">Артикул: </span> {product.article}
                </p>
                <div className="price-block">
                  <div className="prod-quantity">
                    <div
                      className="product-quantity-control"
                      onClick={() => {
                        quantity > 1 && setQuantity(quantity - 1);
                      }}
                    >
                      -
                    </div>
                    <div>{quantity}</div>
                    <div
                      className="product-quantity-control"
                      onClick={() => {
                        quantity < product.quantity &&
                          setQuantity(quantity + 1);
                      }}
                    >
                      +
                    </div>
                  </div>
                  <div>
                    <div>
                      <span className="desc-text">
                        Цена за единицу: {product.price} Br{' '}
                      </span>
                    </div>
                    <div>
                      <span className="desc-text">Цена за всё:</span>
                      <span className="price">{product.price * quantity}</span>
                      <span className="byn"> Br</span>
                    </div>
                  </div>
                </div>
                {/* {product.quantity==0&&<p>Нет на складе</p>} */}
                {/* {product.quantity>0&&+product.quantity<=10&&<p>Осталось немного</p>} */}
                {/* {product.quantity == 'В наличии' && (
                <p className="status-qu">
                  <img src={sclad} alt="есть" />
                  Есть на складе
                </p>
              )} */}
                {product.quantity > 0 ? (
                  <>
                    <div className="status-qu">Есть в наличии</div>
                    <button className="to-cart-btn" onClick={addCart}>
                      В корзину
                    </button>
                  </>
                ) : (
                  <>
                    <div className="status-qu">Под заказ</div>
                    <PreorderForm product={product} />
                  </>
                )}

                <p className="pay-metod">
                  <span className="desc-text">Способы оплаты: </span>
                  <img src={pay} alt="pay" />
                </p>

                <p className="desc-text">
                  Для резервирования товара оформите заказ на сайте или
                  позвоните по телефону
                  <br />
                  8(029)577-46-70
                  <br />
                  8(044)577-46-70
                </p>
                <p>
                  <Link className="garant" to={'/delivery'}>
                    Гарантии на возврат
                  </Link>
                </p>
                {/* <p>{}]</p> */}
              </div>
            </div>
            <div className="all-description">
              {description &&
                description.map((str, i) => <p key={`p_${i}`}>{str}</p>)}
            </div>
            <div className="all-description">
              <p className="desc-text">
                Внимание! Внешний вид товара, комплектация и характеристики
                могут изменяться производителем без предварительных уведомлений.
                Данный интернет-сайт носит исключительно информационный характер
                и ни при каких условиях не является публичной офертой,
                определяемой положениями Статьи 464 Гражданского кодекса
                Республики Беларусь.
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductPage;
