import './HomeContent.css';

const HomeContent = () => {
  return (
    <div className="home-content">
      <h1>Магазин моделей и игрушек для всех возрастов</h1>
      <p>
        Более 14 лет предоставляем качественные модели, игрушки и
        радиоуправляемые модели по приемлемым ценам в Беларуси и Минске. Помощь
        при выборе моделей, аксессуаров и запчастей.
      </p>
      <h2>Самый широкий ассортимент в Беларуси.</h2>
      <p>
        Вы можете приобрести от простых моделей, ориентированных на широкую
        аудиторию, которые подойдут в том числе и начинающим, до уникальных
        устройств, требующих опыта в этом увлекательном деле, купить известные
        бренды моделей и игрушек. Мы являемся одним из первых импортеров в РБ и
        огромным количеством дилеров.
      </p>
      <h2>Для корпоративных и оптовых клиентов</h2>
      <p>
        Работа с корпоративными и оптовыми клиентами является одним из важнейших
        направлений компании. В настоящее время количество постоянных заказчиков
        корпоративного отдела неуклонно растёт. Обращаясь в корпоративный отдел
        компании <a href="https://rcgo.by/">RCGO.by</a> вы всегда получаете
        профессиональную консультацию наших менеджеров.
      </p>
      <p>
        Чтобы собрать заказ или получить консультацию в Минске или другом городе
        Беларуси, свяжитесь с нами по номерам: <br />{' '}
        <a href="tel:375(29)577-46-70">+375 (29) 577-46-70</a> <br />
        <a href="tel:375(44)511-45-13">+375 (44) 511-45-13</a>. <br />
        Мы также принимаем заявки через Viber!
      </p>
    </div>
  );
};

export default HomeContent;
