// import { Routes, Route,BrowserRouter } from "react-router-dom";
// import axios from 'axios';
import { useEffect, useState } from 'react';
import '../static.css';
import chek from '../../../assets/chek.jpg.crdownload';

const About = () => {
  return (
    <div className="static">
      <h2>О нас</h2>

      <p>
        Что же это за хобби такое "Моделизм" ? И с чем его "едят" ?
        <br />
        Вам захотелось новых эмоций? Может новых знаний? Некого увлечения где не
        нужно смотреть в монитор и нажимать на кнопки?
        <br />У нас есть что то особенное для Вас, свой особый сорт "адреналина"
        ! И не имеет значения, новичок Вы в этом чудесном хобби или же опытный
        спортсмен или заядлый "башер" - мы удовлетворим ваши "модельные" запросы
        сполна! Наш ассортимент удивит любого :)
        <br />
        Компания RCGO - Ваш надёжный помощник как в первых, шагах так и в
        тончайших настройках самых передовых моделей! Наши менеджеры - только
        самые опытные моделисты Беларуси! Мы поможем Вам сделать первый шаг в
        этот огромный мир хобби, подберём модель под Ваш вкус и интерес,
        проконсультируем и ответим на все Ваши вопросы! Или вам необходимо
        разобраться "какое же масло залить в передние и какое в задние
        аммортизаторы? Подобрать передаточное отношение? Может двигатель с
        меньшим KV?" - не проблема, мы веников не вяжем :)
        <br />- богатый ассортимент моделей (более 1500 моделей), тысячи позиций
        в магазине и на складе;
        <br />- мы предлагаем только лучшее: качественные модели и сопутствующие
        товары;
        <br />- мы дорожим каждым нашим клиентом - лучшее обслуживание для
        Вашего максимального комфорта;
        <br />- наши курьеры доставят Ваш заказ в любую точку Минска;
        <br />- вам необходима доставка в другие города Беларуси? Не проблема –
        мы отправим ваш заказ почтой или транспортной компанией!
        <br />- каждый наш клиент, наш друг - ведь все моделисты друзья! Мы
        хотим чтобы вы к нам приходили снова и снова, а для постоянных клиентов
        - скидки!;
        <br />- гарантия на весь приобретенный у нас товар в течении двух
        месяцев;
        <br />
        Мы не останавливаемся на достигнутом. Мы постоянно улучшаем наш сервис,
        расширяем ассортимент, создаём максимальный комфорт для наших клиентов!
        <br />
        <b>
          Будьте уверены: сотрудничая с нами, вы приобретаете надёжного партнёра
          на долгие годы!{' '}
        </b>
      </p>

      <h2>Юридическая информация</h2>
      <p>
        Все права на представленные материалы принадлежат компании RCGO.
        Воспроизведение или распространение указанных материалов в любой форме
        может производиться только с письменного разрешения правообладателя. При
        использовании, ссылка на правообладателя и источник заимствования
        обязательна. Логотипы RCGO.by являются зарегистрированными товарными
        знаками компании RCGO.
      </p>
      <p>
        В соответствии со статьей 39 Закона Республики Беларусь «Об авторском
        праве и смежных правах» (далее – Закон) контрафактными являются
        экземпляры произведения, записанного исполнения, фонограммы, передачи
        организации эфирного или кабельного вещания, изготовление,
        распространение или иное использование которых влечет за собой нарушение
        авторского права и смежных прав.
        <br />
        Меры гражданско-правовой ответственности за нарушение авторского и
        смежных прав определены в статье 40 Закона. Так, правообладатель вправе
        требовать:
        <br />
        признания авторского или смежных прав;
        <br />
        восстановления положения, существовавшего до нарушения авторского или
        смежных прав;
        <br />
        пресечения действий, нарушающих авторские или смежные права или
        создающих угрозу их нарушения;
        <br />
        возмещения убытков, включая упущенную выгоду;
        <br />
        взыскания дохода, полученного нарушителем вследствие нарушения
        авторского права и смежных прав, вместо возмещения убытков;
        <br />
        выплаты компенсации в сумме от 10 до 50 тысяч базовых величин,
        определяемой судом, вместо возмещения убытков или взыскания дохода с
        учетом существа правонарушения;
        <br />
        принятия иных предусмотренных законодательными актами мер, связанных с
        защитой авторского или смежных прав.
        <br />
        Кроме того, при защите авторского права и смежных прав могут применяться
        статьи 11 и 989 Гражданского кодекса Республики Беларусь (см.
        приложение: названные статьи ГК).
        <br />В соответствии с постановлением Пленума Верховного Суда Республики
        Беларусь, Пленума Высшего Хозяйственного Суда Республики Беларусь от 22
        июня 2000 года № 4/3 «О разграничении подведомственности дел между
        общими и хозяйственными судами» дела по спорам, вытекающим из применения
        законодательства, регулирующего имущественные и личные неимущественные
        отношения, возникающие в связи с созданием, правовой охраной и
        использованием объектов интеллектуальной собственности (в том числе
        споры, связанные с авторским правом и смежными правами) подведомственны
        Верховному Суду Республики Беларусь.
        <br />
        Административная ответственность за нарушение авторского права и смежных
        прав определяется статьей 9.21 Кодекса Республики Беларусь об
        административных правонарушениях, в соответствии с которой незаконное
        распространение или иное незаконное использование объектов авторского
        права, смежных прав или объектов права промышленной собственности влекут
        наложение штрафа на юридическое лицо до трехсот базовых величин с
        конфискацией предмета административного правонарушения или без
        конфискации.
        <br />
        Уголовная ответственность за нарушение авторских и смежных прав
        предусмотрена статьей 201 Уголовного кодекса Республики Беларусь,
        согласно которой незаконное распространение или иное незаконное
        использование объектов авторского права, смежных прав или объектов права
        промышленной собственности, совершенные в течение года после наложения
        административного взыскания за такое же нарушение или сопряженные с
        получением дохода в крупном размере, наказываются общественными
        работами, или штрафом, или ограничением свободы на срок до трех лет, или
        лишением свободы на срок до двух лет.
      </p>

      <p>
        <span>ООО "Радиоуправляемые модели"</span>
        <br />
        <span>Зарегистрирован </span>Минским горисполкомом 25 октября 2013 года.
        <br /> <span>Юридический адрес: </span>220049, г.Минск, ул.Волгоградская
        13, кабинет 213-159
        <br /> <span>Физический адрес: </span>220049, г.Минск, ул.Волгоградская
        13, кабинет 213-159
        <br /> <span>УНП: </span>192149079
      </p>
      <p>
        <span>Рс BYN: </span>BY23ALFA30122558490010270000 в ЗАО «Альфа-Банк»,
        220012 г. Минск, г. Минск, ул. Сурганова, 43-47
        <br />
        <span>БИК </span>ALFABY2X
      </p>
      <p>
        Зарегистрирован в торговом реестре Республики Беларусь 20.11.2013
        <br />
        <span>Директор: </span>Нарутович Андрей Николаевич
      </p>
    </div>
  );
};

export default About;
